import React, { useState } from "react";

import "./landingModal.css";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
// import QRCode from "react-qr-code";
import bananLogo from "../images/bananalogo.png";
import monkeyLogo from "../images/bg-remove.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import {Link} from 'react-router-dom'
function getModalStyle() {
    const top = 50;
    const left = 50;

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}
const useStyles = makeStyles((theme) => ({
    paper: {
        position: "absolute",
        width: 650,
        height: 400,
        backgroundColor: "#a44c7c",
        borderStyle: "none",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        textAlign: "center",
        borderRadius: "30px 30px 30px 30px",

        ['@media (max-width:520px)']: { // eslint-disable-line no-useless-computed-key
            width: 400,
            height: 350,

        },
        ['@media (max-width:400px)']: { // eslint-disable-line no-useless-computed-key
            width: 330,
            height: 250,

        }
    },
}));

const LandingModal = ({ open, handleConnectClose }) => {
    const [qrCode, setQrCode] = useState(false);
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);


    return (
        <div>
            <>
                <div style={modalStyle} className={classes.paper}>
                    <div className="lm-main-container">
                        <div className="lm-first-img-container">
                        <Link style={{textDecoration:"none"}} to="/" onClick={handleConnectClose}>
                            <img src={bananLogo} className="lm-first-img" />
                            <h3 style={{color:"white"}}>Banana Coin</h3>
                        </Link>
                        </div>
                        
                        <div className="lm-">
                        <Link style={{textDecoration:"none"}} to="/monkey_coin" onClick={handleConnectClose}>
                            <img src={monkeyLogo} className="lm-first-img"/>
                            <h3 style={{color:"white"}}>Monkey Coin</h3>

                        </Link>
                        </div>
                    </div>
                </div>
            </>
        </div>
    );
};

export default LandingModal;

