import React, { useEffect, useCallback, useState } from "react";

import "./modal.css";
import { makeStyles } from "@material-ui/core/styles";
// import Button from "../button/Button";
import { Fade, Modal, Backdrop } from "@material-ui/core";
import { useStore } from "../../../context/GlobalState";
// import { loadBlockchain } from "../../store/asyncActions";
import TextField from "@material-ui/core/TextField";
// import QRCode from "react-qr-code";
// import metaIcon from "../../assets/metamask.png";
// import walletIcon from "../../assets/wallet.png";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { buyTokensAsync } from '../../../store/asyncActions';
import Logo from '../../../images/banana-big.png'


function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}
const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 390,
    height: 370,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    textAlign: "center",
    borderRadius: "30px 30px 30px 30px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",


  },
  textField: {
    width: "100%"
  },
  btn: {
    background: "#1077b3",
    borderRadius: "20px",
    color: "white",
    width: "100%",
    padding: "10px 10px",
    fontSize: "22px",
    marginTop: "10px",
    cursor: "pointer"
  }
}));

const ModalBox = ({ open }) => {
  const [qrCode, setQrCode] = useState(false);
  const [{ web3, accounts, contract }, dispatch] = useStore();
  const [etherAmount, setEtheAmount] = useState("0");
  const [rate, setRate] = useState("");

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const sendRequest = useCallback(async () => {
    // loadBlockchain(dispatch);
  }, []);

  const onSubmit = async () => {
    // e.preventDefault();
    // setAuction(formData)
    // console.log("thi is auction auciton", formData);
    // setTransactionSuccessful(true);
    // setTransactionError("");
    let etherToWei = etherAmount * 10e17;
    let stringEtherToWei = etherToWei.toString();
    const account = accounts;
    console.log("this is for accounts", accounts)

    try {
      // setTransactionInprocess(true)
      // console.log("This is form data from dispatch async", auction);
      await buyTokensAsync(account[0], accounts, contract, stringEtherToWei, dispatch);

      // setTransactionInprocess(false);
      // setTransactionSuccessful(true);
    } catch (error) {
      console.log("error trax = ", error);
      // setTransactionInprocess(false);
      // setTransactionSuccessful(false);
      // setTransactionError(error.message);
    }
  };

  useEffect(() => {
    if (web3 != null && contract != null) {
      const handleRate = async () => {
        try {
          // let rate = await rateAsync(contract);

          // setRate(rate)
        }
        catch (error) {
          console.log("this is ", error)
        }
      }
      handleRate();

    }

  }, [web3, contract])


  console.log("this is modal rate", rate)
  return (
    <div>
      <>
        <div style={modalStyle} className={classes.paper}>
          <h5 className="maga-para" style={{ color: "black" }}> 1 BNB = {(1000000000).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} banana </h5>
          <img
            src={Logo}
            className="modal-img modal-rotate" />
          {/* <h1 style={{ color: "black" }}>Buy Area 51 Tokens</h1> */}
          {
            etherAmount >= 0.1 ?
              <h5 className="maga-cal" style={{ color: "black" }}>{(((etherAmount * 10e17) / 1000000000).toFixed(0)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} Banana  </h5>
              :
              <h5 className="maga-cal" style={{ color: "black" }}>Minimum 0.1 bnb required </h5>

          }
          <TextField className="text-field" label="Enter BNB" value={etherAmount} onChange={(e) => setEtheAmount(e.target.value)}
          />
          {
            etherAmount >= 0.1 ?
              <button className="buy-btn" onClick={onSubmit}
              >
                Buy Now
            </button> :
              <button className="buy-btn-disabled" onClick={onSubmit} disabled
              >
                Buy Now
               </button>
          }

        </div>
      </>
    </div>
  );
};

export default ModalBox;
