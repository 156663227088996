import React, { useEffect, useCallback, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import Modal from "@material-ui/core/Modal";
import { useStore } from "../../../context/GlobalState";
import { loadBlockchain, loadWalletConnect } from "../../../store/asyncActions";
import ModalBox from "../modal/index";
import ConnectModal from "../modal/connectModal";
import Logo from "../../../images/banana-big.png";
import './header.css'

function Header() {
  const [{ web3, accounts, contract }, dispatch] = useStore();
  const [userAccount, setUserAccount] = React.useState("");

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(false);
  const [openConnect, setOpenConnect] = React.useState(false);

  const handleMetamask = useCallback(async () => {
    loadBlockchain(dispatch);
  }, []);
  const handleWalletConnect = useCallback(async () => {
    loadWalletConnect(dispatch);
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConnectOpen = () => {
    setOpenConnect(true);
  };

  const handleConnectClose = () => {
    setOpenConnect(false);
  };
  useEffect(() => {
    if (web3 != null) {
      handleConnectClose();
    }
  }, [web3]);

  useEffect(async () => {
    if (web3 != null) {
      let accountCheck = await accounts[0];
      setUserAccount(accountCheck);
    }
  }, [accounts]);
  return (
    <div className="header w-nav">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand brand w-nav-brand w--current" href="#">
            <img
              // src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69b469c3d95f_happy%20logo.png"
              src={Logo}
              width="77"
              alt="logo"
              className="header-logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <MenuIcon />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li
            
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="#why-happy2"
                  data-toggle="collapse"
                >
                  why $banana
                </a>
              </li>
              <li
                
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper"
              >
                <a
                data-toggle="collapse"
                className="nav-link nav-link-2"
                  aria-current="page"
                  href="#tokenomics"
                >
                  tokenomics
                </a>
              </li>
              <li
                
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper"
              >
                <a
                data-toggle="collapse"
                className="nav-link nav-link-2"
                  aria-current="page"
                  href="#roadmap"
                >
                  roadmap
                </a>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="#Meet-The-Founder"
                >
                  meet the founder
                </a>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="#Meet-The-Co-Founder"
                >
                  cofounder
                </a>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="#where-we-donate"
                >
                  charities decided
                </a>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="#FAQ"
                >
                  faq
                </a>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="#contactUs"
                >
                  Contact us
                </a>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="http://nft.banana-coin.org/"
                  target="_blank"
                >
                 NFT Marketplace
                </a>
              </li>
            </ul>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {/* {web3 != null ? (
                <a
                  style={{ textDecoration: "none", cursor: "not-allowed" }}
                  className="buy-happy-button"
                >
                  buy $Banana
                </a>
              ) : (
                <a
                  style={{ textDecoration: "none", cursor: "not-allowed",color:"white" }}
                  className="buy-happy-button"
                >
                  Unlock Wallet
                </a>
              )} */}
            </ul>
          </div>
        </div>
      </nav>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBox />
      </Modal>

      <Modal
        open={openConnect}
        onClose={handleConnectClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ConnectModal />
      </Modal>
    </div>
  );
}

export default Header;
