import React from "react";
import Logo from "../../../images/bg-remove.png";

function Footer() {
  return (
    <footer className="footer">
      <div className="container-default">
        <div className="top-content">
          <div className="animation">
            <a href="/" className="brand-footer w-nav-brand w--current">
              <img
                // src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69b469c3d95f_happy%20logo.png"
                src={Logo}
                width={100}
                // width="226"
                alt=""
                className="footer-logos"
              />
            </a>
          </div>
          <p className="paragraph footer-paragraph">
          Making Donations to the Hospitality Industry,one Hospitality Monkey Coin At a time
          </p>
        </div>
        <div className="divider footer-divider"></div>
        <div className="w-layout-grid footer-menu-grid">
          <div className="newsletter-wrapper">
            <div className="w-layout-grid footer-social-media-grid">
              <a
                 href="http://twitter.com/bananacoin2"
                target="_blank"
                className="social-media-link twitter small w-inline-block"
              >
                <img
                  src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69ebdec3d93e_icon-social-media-02-designer-template.svg"
                  alt=""
                  className="image social-media"
                />
              </a>
              <a
                href="https://t.me/bananacoin88"
                target="_blank"
                className="social-media-link twitter small w-inline-block"
              >
                <img
                  src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69d1c9c3d97e_124019.png"
                  alt=""
                  className="image social-media"
                />
              </a>
              <a
                id="w-node-_64c50f73-bd1e-b913-4143-b7f2c855c12d-ae2f46c5"
                href="https://www.instagram.com/banana_coincrypto"
                target="_blank"
                className="social-media-link linkedin small w-inline-block"
              >
                <img
                  src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69e01ac3d9aa_new_instagram_logo-1024x1024.jpg"
                  sizes="42px"
                  alt=""
                  className="image social-media"
                />
              </a>
              <a
                id="w-node-_10f394be-dff8-f33f-a3eb-5f0286aea3ac-ae2f46c5"
                href="https://medium.com/@bananacoincrypto"
                target="_blank"
                className="social-media-link linkedin small w-inline-block"
              >
                <img
                  src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69ccbac3d9a8_1200px-Medium_logo_Monogram.svg.png"
                  sizes="42px"
                  alt=""
                  className="image social-media"
                />
              </a>
              <a
                id="w-node-a6fa9313-9e5c-2c4b-247d-c37759738195-ae2f46c5"
                href="https://twitch.tv/bananacoincrypto"
                target="_blank"
                className="social-media-link linkedin small w-inline-block"
              >
                <img
                  src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69139dc3d985_Screen%20Shot%202021-04-23%20at%204.35.27%20PM.png"
                  alt=""
                  className="image social-media"
                />
              </a>
              <a
                id="w-node-_0fb5e068-7202-333a-5e68-99fccda729c8-ae2f46c5"
                href="https://discord.gg/qZ8waHpJr5"
                target="_blank"
                className="social-media-link linkedin small w-inline-block"
              >
                <img
                  src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add691ca2c3d9a9_1536500670.png"
                  alt=""
                  className="image social-media"
                />
              </a>
              <a
                href="https://www.reddit.com/r/bananacoincrypto"
                target="_blank"
                className="social-media-link linkedin small w-inline-block"
              >
                <img
                  src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69f74ac3d9a6_Reddit%20Logo.jpeg"
                  sizes="42px"
                  alt=""
                  className="image social-media"
                />
              </a>
              <a
                href="https://facebook.com/bananacoincrypto"
                target="_blank"
                className="social-media-link linkedin small w-inline-block"
              >
                <img
                  src="https://cdn.icon-icons.com/icons2/2108/PNG/512/facebook_icon_130940.png"
                  sizes="42px"
                  alt=""
                  className="image social-media"
                />
              </a>
            </div>
          </div>
          <div className="nav-menu-footer-wrapper">
            <div className="nav-menu-footer">
              <div className="nav-menu-footer-title">go to</div>
              <div className="nav-menu-footer-grid">
                <ul className="list-footer w-list-unstyled">
                  <li className="footer-list-item">
                    <a href="#why-happy2" className="footer-link">
                      why $Monkey
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a href="#tokenomics" className="footer-link">
                      tokenomics
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a href="#roadmap" className="footer-link">
                      roadmap
                    </a>
                  </li>
                  <li className="footer-list-item">
                    <a href="#where-we-donate" className="footer-link">
                      where we donate
                    </a>
                  </li>
                </ul>
                <div className="space nav-footer menu"></div>
                <ul className="list-footer w-list-unstyled"></ul>
              </div>
            </div>
          </div>
        </div>
        <div className="small-print copyright">
          Monkey Coin
          {/* <a href="mailto:contact@thehappycoin.co">info@thehappycoin.co</a> */}
          <a href="https://webflow.com/" target="_blank"></a>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
