import React from "react";

function RoadMap() {
  return (
    <div id="roadmap" className="section-copy experience">
      <div className="container-large-1131px w-container">
        <div className="experience-wrapper">
          <div className="split-content experience-left">
            <div
              data-w-id="d23b6533-20aa-100c-6817-34ece76dab37"
              className="experience-left-content"
            >
              <div className="accent"></div>
              <h2>roadmap</h2>
              <p className="paragraph-large">
                {/* We can’t simply believe in equality in education. Equality in
                Education Isn’t Enough. We have to create it. Check out our
                roadmap and see what we have planned to keep this community and
                charity going. Community events, giveaways, an NFT marketplace,
                and merchandise are all on the way! */}
              </p>
            </div>
          </div>
          <div className="split-content" style={{ position: "relative" }}>
            <div className="w-layout-grid timeline-grid">
              <div className="timeline-wrapper monkey">
                <div className="timeline-dot"></div>
                <div
                  className="timeline-dot"
                  style={{ marginTop: "100px" }}
                ></div>
                <div className="timeline-dot"></div>
                <div className="timeline-dot"></div>
                <div className="timeline-dot"></div>
                <div className="timeline-dot"></div>
                <div className="timeline-dot"></div>
                <div className="timeline-dot"></div>
                {/* <div className="timeline-dot"></div> */}
                <div className="timeline-dot"></div>
                {/* <div className="timeline-dot"></div> */}

                {/* <div className="timeline-dot" style={{marginTop: "100px"}}></div>
                <div className="timeline-dot"></div>            */}

                <div className="timeline-line-wrapper monkey">
                  <div className="time-line"></div>
                </div>
              </div>
              <div className="monkey-roadmap">
                <div className="card timeline">
                  <h4 className="title card-timeline-title">
                    2022 Q1 | Concept & Research
                  </h4>
                  <div className="card-timeline-text">
                    1️⃣&nbsp;&nbsp;&nbsp;Establishment Of The Hospitality Monkey
                    Coin Project Main Purpose. <br />
                    2️⃣&nbsp;&nbsp;&nbsp;&nbsp;Research In: Blockchain, Ecosystem
                    Development, Platform Development For Hospitality Industry.
                    <br />
                    3️⃣&nbsp;&nbsp;&nbsp;&nbsp;Formation Of The Hospitality
                    Monkey Coin Team.
                    {/* 🥰&nbsp;&nbsp;&nbsp;Second Donation (
                  <a
                    href="https://www.twitch.tv/videos/1006498669"
                    target="_blank"
                  >
                    Watch
                  </a>
                  )&nbsp;✅ */}
                    <br />
                  </div>
                </div>
                <div
                  data-w-id="d23b6533-20aa-100c-6817-34ece76dab4d"
                  className="card timeline"
                >
                  <h4 className="title card-timeline-title">
                    2022 Q2 | Launching ICO and Pancakeswap
                  </h4>
                  <div className="card-timeline-text">
                    1️⃣&nbsp;&nbsp;&nbsp;Choosing the right blockchain for the
                    project. <br />
                    2️⃣&nbsp;&nbsp;&nbsp;&nbsp;Hospitality Monkey Coin genesis
                    and testing.
                    <br />
                    3️⃣&nbsp;&nbsp;&nbsp;&nbsp;Website development & design.
                    <br />
                    ️4️⃣&nbsp;&nbsp;&nbsp;&nbsp;Whitepaper.
                    <br />
                    5️⃣&nbsp;&nbsp;&nbsp;&nbsp;Launching our ICO.
                    <br />
                    6️⃣&nbsp;&nbsp;&nbsp;&nbsp;After Presale ICO soft cap and
                    hard cap were filled, will be launched on Pancakeswap.
                    <br />
                    7️⃣&nbsp;&nbsp;&nbsp;&nbsp;First Donation Will Be Made.
                    <br />
                    8️⃣&nbsp;&nbsp;&nbsp;&nbsp;Adoption of the latest legislative
                    norms and registration of the company.
                  </div>
                </div>
                <div className="card timeline">
                  <h4 className="title card-timeline-title">
                    2022 Q3 | App Development and Testing Mobile App UI Design.
                  </h4>
                  <div className="card-timeline-text">
                    1️⃣&nbsp;&nbsp;&nbsp;In-House Testing Of Our Mobile App
                    Functionality. <br />
                    2️⃣&nbsp;&nbsp;&nbsp;&nbsp; Testing For The Back-End
                    Platform.
                    <br />
                    3️⃣&nbsp;&nbsp;&nbsp;&nbsp;Premium Investors Start Voting For
                    Future Developments.
                    <br />
                    ️4️⃣&nbsp;&nbsp;&nbsp;&nbsp;Digital Business Card for
                    business owners to help saving the planet and cutting costs
                    of printing regular business cards.
                    <br />
                    5️⃣&nbsp;&nbsp;&nbsp;&nbsp;QR Menu to help business owners to
                    simplify their operations by letting customers scanning
                    their QR Menu and look at the menus on their phones and
                    order from their phones. Helping business owners to cut down
                    on labor and the cost of printing menus.
                    <br />
                    6️⃣&nbsp;&nbsp;&nbsp;&nbsp; Job search website for business
                    owners to post hiring ads and find employees.
                    <br />
                    7️⃣&nbsp;&nbsp;&nbsp;&nbsp;We are aiming to be the One Stop
                    Shop to help the hospitality industry from hiring staffs,
                    serving customers to deliver the food to the customers. We
                    are striving to create all kinds of employment and business
                    opportunities.
                    <br />
                  </div>
                </div>
                <div className="card timeline">
                  <h4 className="title card-timeline-title">
                    2022 Q4 | Distribution
                  </h4>
                  <div className="card-timeline-text">
                    1️⃣&nbsp;&nbsp;&nbsp;Begin testing in the real environment.
                    <br />
                    2️⃣&nbsp;&nbsp;&nbsp;&nbsp;Create a business relations team.
                    <br />
                    3️⃣&nbsp;&nbsp;&nbsp;&nbsp;Submit the mobile app & wallet on
                    the Apple App Store & Android Market.
                    <br />
                    4️⃣ Launching Monkey Delivery & Ongster Care
                  </div>
                </div>
                <div className="card timeline">
                  <h4 className="title card-timeline-title">
                    2023 Q1 | Community Benefits
                  </h4>
                  <div className="card-timeline-text">
                    1️⃣&nbsp;&nbsp;&nbsp; Meetings And Conferences With Our
                    Partners. <br />
                    2️⃣&nbsp;&nbsp;&nbsp;&nbsp;Registration Of Hospitality
                    Industry Partners In The Hospitality Monkey Coin Ecosystem.
                    <br />
                    3️⃣&nbsp;&nbsp;&nbsp;&nbsp;Launching Of Our Own
                    BananaMonkeyFundMe NFT Marketplace To Help Students/Families
                    Sell NFT Products And Acquire Financial Support. Similar To
                    Gofundme.Com But The Donors Will Get Very Unique NFTs In
                    Return. Think About It, If The Student They Donated Became
                    President Of A Country, This NFT Will Worth A Lot. So Not
                    Only Donors Can Donate But Also They Have A Great Investment
                    In Their Hands Too.
                  </div>
                </div>
                <div className="card timeline">
                  <h4 className="title card-timeline-title">
                    2023 Q2 | Focus On Market
                  </h4>
                  <div className="card-timeline-text">
                    1️⃣&nbsp;&nbsp;&nbsp; Listing Hospitality Monkey Coin On
                    Crypto Exchanges.
                    <br />
                    2️⃣&nbsp;&nbsp;&nbsp;&nbsp; Advertising Hospitality Monkey
                    Coin On All Social Media Platforms, İnfluencer Marketing,
                    Billboards, TV And Other Marketing Campaigns.
                  </div>
                </div>
                <div className="card timeline">
                  <h4 className="title card-timeline-title">
                    2023 Q3 | Integration
                  </h4>
                  <div className="card-timeline-text">
                    1️⃣&nbsp;&nbsp;&nbsp;Launching Hospitality Monkey Coin
                    stacking options and yield farming. Will be able to swap
                    with $BANANA Coin in our ecosystem with our own SWAP.
                    <br />
                    2️⃣&nbsp;&nbsp;&nbsp;&nbsp;Launching a crypto exchange on our
                    platform.
                    <br />
                    3️⃣&nbsp;&nbsp;&nbsp;&nbsp;Start development of plugins for
                    integration with other platforms.
                  </div>
                </div>

                <div className="card timeline">
                  <h4 className="title card-timeline-title">
                    2023 Q4 | Other Developments
                  </h4>
                  <div className="card-timeline-text">
                    1️⃣&nbsp;&nbsp;&nbsp; Introducing In App Advertising Module
                    For Partners.
                    <br />
                    2️⃣&nbsp;&nbsp;&nbsp;&nbsp; Implementing Widgets And
                    Extensions For Smartwatches.
                    <br />
                    3️⃣&nbsp;&nbsp;&nbsp;&nbsp; Option For Partners To Send
                    Targeted Notifications.
                  </div>
                </div>

                <div className="card timeline">
                  <h4 className="title card-timeline-title">
                    2024 Q1 | Plans For The Future
                  </h4>
                  <div className="card-timeline-text">
                    1️⃣&nbsp;&nbsp;&nbsp;New ideas for user loyalty.
                    <br />
                    2️⃣&nbsp;&nbsp;&nbsp;&nbsp;Testing new implementations.
                    <br />
                    3️⃣&nbsp;&nbsp;&nbsp;&nbsp;Expansion research..
                    <br />
                    4️⃣ MetaVerse
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoadMap;
