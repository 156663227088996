import React from "react";
import auditReportImg from '../../../images/audit.jpeg';
import WhitePaper from '../$BANANA Coin.pdf'
function WhyHappy() {
  return (
    <div
      id="why-happy2"
      // overflow-hidden="overflow: hidden;"
      className="section happy products-copy-copy-copy"
    >
      <div className="container-medium-655px products w-container">
        <div className="accent featured-products"></div>
        <h1 className="heading-2">the world needs $BANANA Coin</h1>
        <p className="paragraph-large-copy">
          Nearly two-thirds of jobs in the United States require at least some
          college education, if not a full or advanced degree. Last year,
          however, Forbes reported that the cost of college is increasing nearly
          eight times faster than wages, and student loans now account for a
          greater percentage of non-housing debt than credit cards and auto
          loans. What are today’s students to do? As we enter another election
          cycle, candidates on both sides are discussing the issue of the rising
          cost of an education. They’re proposing and debating a number of ideas
          to help students earn their degrees, while protecting them from
          insurmountable debt that will have a lasting effect on our economy.
          While we wait for the outcomes of these discussions, today’s students
          are facing difficult decisions about their futures, including whether
          or not college is an affordable expense. Fortunately, there are a
          number of highly-rated organizations committed to helping students
          from all walks of life get a degree that will help them build brighter
          futures for themselves, their families, their communities, our
          country, and our world. It’s easy to think of someone else’s education
          expenses as “their” issue, but access to education should be an issue
          we all stand behind because it has a personal, social, and economic
          impact on all of us.
          <strong className="bold-text-2">Banana Coin</strong> is a token that
          sets out to change this.
        </p>
        <a
        // href="https://firebasestorage.googleapis.com/v0/b/cdn-clients-other-use.appspot.com/o/images%2Fdoc.docx?alt=media&token=9d565636-b1ce-447b-ab53-7814ca94a87a"
        href={WhitePaper}  
        target="_blank"
          className="button-secondary-copy-copy-copy-copy-copy-copy w-button"
        >
          read the whitepaper
        </a>
        <a
          target="_blank"
          className="button-secondary-copy-copy-copy-copy-copy-copy-copy w-button"
          href="https://dessertswap.finance/audits/Banana%20Coin%20BEP-20%20Transparency%20Audit%209128074.pdf"
        >
          see the Dessertswap audit
        </a>
      </div>
      <div id="why-happy" className="lottie-animation-copy-copy"></div>
    </div>
  );
}

export default WhyHappy;
