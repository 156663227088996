import React, { useEffect, useCallback, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import Modal from '@material-ui/core/Modal';
import { useStore } from '../../../context/GlobalState';
import ModalBox from '../modal/index';
import ConnectModal from '../modal/connectModal'
import { ToastContainer, toast } from 'react-toastify';
import Web3 from "web3";

import 'react-toastify/dist/ReactToastify.css';
function Cta() {
  const [{ web3, accounts,network, contract }, dispatch] = useStore();

  const [open, setOpen] = React.useState(false);
  const [openConnect, setOpenConnect] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConnectOpen = () => {
    setOpenConnect(true);
  };

  const handleConnectClose = () => {
    setOpenConnect(false);
  };
  useEffect(() => {
    if (web3 != null) {
      handleConnectClose()
    }
  }, [web3])
  useEffect(() => {

    if (Web3.givenProvider != null && Web3.givenProvider.chainId != 0x38 ) {
        const notify = () => toast.error('Please Connect With Binance Chain!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
        });;
        notify();
    }
}, [network])
  return (
<>
    <div className="section cta">
      <div className="container-default-copy w-container">
        <div className="split-content cta-left">
          <div className="accent cta"></div>
          <h2 className="title cta">Build One More School can Eliminate One More Jail.</h2>
          <p className="paragraph-large cta">
          The $BANANA Coin team is on a mission to make the world full of educated students with better chances to succeed in their careers, while earning you money. We're quickly building an amazing community and having a ton of fun along the way. Join us!
          </p>
          <div className="animation">
          {/* {
              web3 != null ?
              <a  style={{cursor:"not-allowed"}} className="buy-happycoin-4 cta w-button">
              buy Banana Coin
            </a> :
             <a style={{cursor:"not-allowed"}} className="buy-happycoin-4 cta w-button">
             Unlock Wallet
           </a>
          } */}
            <a
              href="https://t.me/bananacoin88"
              target="_blank"
              className="button-secondary-copy-copy-copy-copy cta w-button"
            >
              join the telegram
            </a>
          </div>
        </div>
      </div>
    </div>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBox />
      </Modal>

      <Modal
        open={openConnect}
        onClose={handleConnectClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ConnectModal />
      </Modal>
      {/* <ToastContainer /> */}

    </>
  );
}

export default Cta;
