import React from 'react';
import video1 from '../../../videos/jonBnanana.mp4'
import video2 from '../../../videos/bananaCoin2.mp4'
import video3 from '../../../videos/bananaCoin3.mp4'
// import '../../App.css';
import './videoSection.css'


const VideoSection = () => {
    return (
        <div className="container platform-banana box">
            <div className="title-box">
                {/* <h1 className="title" title="Reviews">
                WELCOME CTCWTF FRIENDS                </h1> */}
            </div>

            <div className="button-box">
                <div className="video1">
                    <video controls  className="videos1" >
                        <source src={video1} type="video/mp4"   />
                    </video>
                    <h6 className="vs-heading">Jon Taffer

American entrepreneur and television personality. He is best known as host of the reality series Bar Rescue. He is also credited largely with creating the concept for NFL Sunday Ticket.</h6>

                </div>

                <div className="video1">
                    <video controls  className="videos1"  >
                        <source src={video2} type="video/mp4"  />
                    </video>
                    <h4 className="vs-heading">Randy Couture 6X UFC Champion Actor</h4>
                </div>


                <div className="video1">
                    <video controls  className="videos1"  >
                        <source src={video3} type="video/mp4"  />
                    </video>
                    <h4 className="vs-heading">Kurt Angle Olympic Gold Medalists 🏅 6X WWE Champion </h4>
                </div>

            </div>


        </div>
    )
}

export default VideoSection
