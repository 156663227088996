import React from "react";
import founder from '../../../images/founder.jpg'
function F() {
  return (
    <section id="Meet-The-Founder" className="section my-story">
      <div className="container-default w-container">
        <div className="my-story-wrapper">
          <div
            className="split-content my-story-left"
          >
            <img
              src={founder}
              // srcSet="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add6984d2c3d9a4_output-onlinepngtools-p-500.png 500w, https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add6984d2c3d9a4_output-onlinepngtools.png 800w"
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 91vw, (max-width: 991px) 552px, (max-width: 1439px) 42vw, 553.078125px"
              alt=""
              className="image my-story"
              // width="600px"
              // height="600px"

            />
          </div>
          <div className="split-content my-story-right">
            <div
              className="accent"
            ></div>
            <h2
              data-w-id="9ea96bae-6205-c76e-fdfc-a105dc2e8578"
            >
              Meet the founder
            </h2>
            <p className="paragraph-large">
            Hi, my name is Chung, I'm a serial entrepreneur. I had owned multiple struggling businesses
             and turned them around since I was 19. I am very dedicated and will never give up till a 
             project is completely done. I am a real estate investor and developer and also a licensed FL 
             Business Broker. Managed over 200 employees in my businesses collectively. I am ready to take
              on this great 
            project to help a lot of students who are desperately need help in funding for their education.
              {/* <a
                href="https://www.linkedin.com/in/sawyerwildgen/"
                target="_blank"
              >
               a struggling franchise in Orlando and I was the youngest franchisee in the system at that time
              </a>
              , and managed over 200 employees in my ventures collectively.
              After exiting my most recent company on January 1st, 2021
              I&nbsp;began to draft up the initial concepts for HappyCoin. */}
              <br />
              <br />
              Connect with me on LinkedIn{" "}
              <a
                href="http://linkedin.com/in/chung-ong"
                target="_blank"
              >
                here
              </a>
              .
            </p>
            <div className="divider my-story"></div>
            {/* <h3
            >
              mental illness, and it's role in my life
            </h3>
            <p
              className="paragraph-large"
            >
              Mental illness has had a major impact on my life. With family
              members suffering from schizophrenia and bipolar disorder, I've
              witnessed first hand the stigma that comes along with these
              illnesses, and many times, the lack of effective outlets for
              relief, and treatment.&nbsp;This is what we're going to tackle on
              a global scale with HappyCoin.
            </p> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default F;