import React from "react";
import Bulb from '../../../images/svg/Bulb.svg'
import Distribution from '../../../images/svg/Distribution.svg'
import People from '../../../images/svg/People.svg'
import target from '../../../images/svg/target.svg'


function Tokenomics() {
  return (
    <div id="tokenomics" className="section skills" style={{marginTop: "0px"}}>
      <div className="container-default w-container">
        <div className="top-content top-skills">
          <div className="split-content top-skills-left">
            <div className="accent skills"></div>
            <h2 className="title skills">tokenomics</h2>
          </div>
          <p className="paragraph-large skills">
          We're quickly building an amazing community and having a ton of fun along the way. Help us
           change the world while earning on your investments. It’s the best of both worlds! 
          </p>
        </div>
        <div className="w-layout-grid skills-grid">
          <div className="card skill">
            <img
              // src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69743ac3d952_routing.svg"
              src={Distribution}
              alt=""
              className="icon-skill"
            />
            <h3 className="title card-skill">original supply</h3>
            <p className="paragraph-3" style={{color:"black"}}>
            100,000,000
                          {/* <br />
              30% Burned */}
            </p>
          </div>
          <div className="card skill">
            <img
              // src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69cc71c3d947_target.svg"
              src={target}
              alt=""
              className="icon-skill"
            />
            <h3 className="title card-skill">20% Charity Wallet</h3>
            <p className="paragraph-2">
            20% Charity Wallet
              <br />
              {/* 5% is redistributed to all holders proportionately */}
            </p>
          </div>
          {/* <div className="card skill">
            <img
              src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69719ac3d958_team.svg"
              alt=""
              className="icon-skill"
            />
            <h3 className="title card-skill">BSC Blockchain </h3>
            <p className="paragraph-4">
              On BSC blockchain for lightning fast orders and minimal fees
            </p>
          </div>
          <div className="card skill">
            <img
              src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add698905c3d953_idea.svg"
              alt=""
              className="icon-skill"
            />
            <h3 className="title card-skill">Autolock</h3>
            <p className="paragraph-5">
              Autolock function sends donations into a multisign wallet which
              requires verification from at least five members of the founding
              team to securely access and distribute donation funds 15% tax on
              each transaction
            </p>
          </div> */}
        </div>
        {/* <div
          data-w-id="8064fab0-4982-91cc-798b-e4598c55fb03"
          className="flex-vc"
        >
          <a
            href="https://charts.bogged.finance/?token=0xB0B924C4a31b7d4581a7F78F57ceE1E65736Be1D"
            target="_blank"
            className="view-chart w-button"
          >
            view chart
          </a>
        </div> */}
      </div>
    </div>
  );
}

export default Tokenomics;
