import React from "react";
import founder from "../../../images/founder.jpg";
function F() {
  return (
    <section id="Meet-The-Founder" className="section my-story">
      <div className="container-default w-container">
        <div className="my-story-wrapper">
          <div className="split-content my-story-left">
            <img
              src={founder}
              // srcSet="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add6984d2c3d9a4_output-onlinepngtools-p-500.png 500w, https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add6984d2c3d9a4_output-onlinepngtools.png 800w"
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 91vw, (max-width: 991px) 552px, (max-width: 1439px) 42vw, 553.078125px"
              alt=""
              className="image my-story"
              // width="600px"
              // height="600px"
            />
          </div>
          <div className="split-content my-story-right">
            <div className="accent"></div>
            <h2>Meet the founder</h2>
            <p className="paragraph-large">
              Hi, my name is Chung, I am also the founder of $BANANA Coin which
              is on the mission to make the world full of educated students . I
              know how tough hospitality industry is. I had been in the
              restaurant and bar business for over 10 years. I had turned around
              multiple restaurants and bars. My Bar in Jacksonville Beach was
              doing extremely well before I went to NY to open up 2 restaurants.
              One in Manhattan and one in Brooklyn. But after I left, the bar in
              Jacksonville Beach struggled big time because of mismanagement
              while I was spending my time in NY to open up 2 new restaurants.
              After coming back from NY, I was also featured on a well known TV
              show “Bar Rescue” Fish Out of Blue Water, Bar Rescue episode
              (season 6, episode 14) back in 2018. I really appreciate the help
              from Jon Taffer and Bar Rescue. After Bar Rescue makeover, our
              revenue broke record high but unfortunately the landlord sold the
              building and the new landlord decided to tear down the whole
              building and build a brand new building. Jon Taffer from Bar
              Rescue helped me to save my dream bar so now I am giving back to
              the community and trying to save those struggling businesses in
              the hospitality industry.
              {/* <a
                href="https://www.linkedin.com/in/sawyerwildgen/"
                target="_blank"
              >
               a struggling franchise in Orlando and I was the youngest franchisee in the system at that time
              </a>
              , and managed over 200 employees in my ventures collectively.
              After exiting my most recent company on January 1st, 2021
              I&nbsp;began to draft up the initial concepts for HappyCoin. */}
              <br />
              <br />
              Connect with me on LinkedIn{" "}
              <a href="http://linkedin.com/in/chung-ong" target="_blank">
                here
              </a>
              .
            </p>
            <div className="divider my-story"></div>
            {/* <h3
            >
              mental illness, and it's role in my life
            </h3>
            <p
              className="paragraph-large"
            >
              Mental illness has had a major impact on my life. With family
              members suffering from schizophrenia and bipolar disorder, I've
              witnessed first hand the stigma that comes along with these
              illnesses, and many times, the lack of effective outlets for
              relief, and treatment.&nbsp;This is what we're going to tackle on
              a global scale with HappyCoin.
            </p> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default F;
