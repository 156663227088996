import React, { useState } from "react";
import WhitePaper from '../$BANANA Coin.pdf'
import auditReportImg from '../../../images/audit.jpeg';


function FAQ() {
  const [faq1, setFaq1] = useState(false);
  const [faq2, setFaq2] = useState(false);
  const [faq3, setFaq3] = useState(false);
  const [faq4, setFaq4] = useState(false);
  const [faq5, setFaq5] = useState(false);
  const [faq6, setFaq6] = useState(false);
  const [faq7, setFaq7] = useState(false);
  const [faq8, setFaq8] = useState(false);
  const [faq9, setFaq9] = useState(false);
  const [faq10, setFaq10] = useState(false);
  const [faq11, setFaq11] = useState(false);
  const [faq12, setFaq12] = useState(false);
  const [faq13, setFaq13] = useState(false);
  const [faq14, setFaq14] = useState(false);
  return (
    <div id="FAQ" className="section faqs">
      <div className="container-medium-931px text-center w-container">
        <div className="accent large faqs"></div>
        <h2 className="title faqs">Frequently Asked Questions</h2>
        <div className="w-layout-grid faqs-grid faq-content">
          <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">how do i buy $BANANA?</h3>
              <div className="faq-arrow-wrapper" onClick={() => setFaq1(!faq1)}>
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq1 ? (
              <div className="faq-content">
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq" style={{color:"black"}}>
                  {/* You can check out our full step-by-step guide and video
                  explainer <a href="/buy">here</a>! */}
                  $BANANA Coin is currently being traded on PancakeSwap, which
                  is the primary exchange for Binance Smart Chain (BSC). Here is
                  a step by step guide on how to buy $BANANA:
                  <ol>
                    <li>
                      Head to metamask.io and download their wallet to your
                      phone or chrome/firefox browser.
                    </li>
                    <li>
                      Go to PancakeSwap (pancakeswap.finance). PancakeSwap is
                      where you'll be performing the swap of your current tokens
                      to $BANANA.
                    </li>
                    <li>
                      Get to the trade screen. Click "Connect" at the top right
                      of the screen, and then navigate to "Trade" on the left
                      sidebar.
                    </li>
                    <li>
                      Select the $BANANA token. Click on the "Select a Currency"
                      button, and enter the $BANANA token contract:
                    </li>
                    <li>
                      {" "}
                      Adjust your slippage to 18%. Click "Settings" at the top
                      right, and adjust your slippage to 18% (sometimes it may
                      be a bit more, depending on how much demand there is).
                    </li>
                    <li>
                      Swap away! Enter the amount you want to buy and swap away!
                      Remember to have enough BNB for gas fees! ❤️
                    </li>
                  </ol>
                  <br />
                  <p>
                    *There are quite a few other $BANANA on the BSC network and
                    please make sure you purchase the right $BANANA. Copy and
                    paste the address will be the best way to verify. Thank you.{" "}
                  </p>
                  {/* <a
                    href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x5e90253fbae4dab78aa351f4e6fed08a64ab5590"
                    target="_blank"
                  >
                    <br />
                  </a> */}
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div>

          <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">what is Banana Coin?</h3>
              <div className="faq-arrow-wrapper" onClick={() => setFaq2(!faq2)}>
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq2 ? (
              <div className="faq-content" >
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq" style={{color:"black"}}>
                  {/* Launched on April 20th, 2021, HappyCoin is a charity token
                  that donates to mental health organizations on a weekly basis!
                  With an initial{" "}
                  <a
                    href="https://bscscan.com/address/0x2E5024BbA69E0981cFf8d760ab7Db2b230f78911"
                    target="_blank"
                  >
                    3% charity wallet,
                  </a>{" "}
                  we charge a tax of 10% on every transaction, with 5% burned
                  for deflationary purposes, and 5% redistributed to holders to
                  reward investors and keep the charity wallet flowing. */}
                  Launched on June 26th, 2021, $BANANA Coin is a charity token
                  that donates to colleges! We charge a tax of 15% on every
                  transaction, with 5% burned for deflationary purposes, and 5%
                  redistributed to holders to reward investors, 5% will be going to the liquidity pool and keep the
                  charity wallet flowing.
                  
                  <br />
                  <br />
                  Initial supply: 1,000,000,000,000,000
                  <a
                    href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x5e90253fbae4dab78aa351f4e6fed08a64ab5590"
                    target="_blank"
                  >
                    <br />
                  </a>
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div>
          <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">are you on social media?</h3>
              <div className="faq-arrow-wrapper" onClick={() => setFaq3(!faq3)}>
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq3 ? (
              <div className="faq-content">
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq" style={{color:"black"}}>
                  Of course!&nbsp;Our socials can be found below..
                  <br />
                  <br />
                  🐦&nbsp;&nbsp;&nbsp;
                  <a href="http://twitter.com/bananacoin2" target="_blank">
                    Twitter
                  </a>
                  <br />
                  🤳&nbsp;&nbsp;&nbsp;
                  <a
                href="https://twitch.tv/bananacoincrypto"
                target="_blank"
                  >
                    Twitch
                  </a>
                  <br />
                  📸&nbsp;&nbsp;&nbsp;
                  <a
                    href="https://www.instagram.com/banana_coincrypto"
                    target="_blank"
                  >
                    Instagram
                  </a>
                  <br />✍ &nbsp;&nbsp;
                  <a
                href="https://medium.com/@bananacoincrypto"
                >
                    Medium
                  </a>
                  <br />
                  🤳&nbsp;&nbsp;&nbsp;
                  <a href="https://t.me/bananacoin88" target="_blank">
                    Telegram
                  </a>
                  <br />
                  👋&nbsp;&nbsp;&nbsp;
                  <a
                href="https://discord.gg/qZ8waHpJr5"
                target="_blank"
                  >
                    Discord
                    <br />
                  </a>
                  🔴&nbsp;&nbsp;&nbsp;
                  <a
                    href="https://www.reddit.com/r/bananacoincrypto"
                    target="_blank"
                  >
                    Reddit
                  </a>
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div>
          <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">how are charities decided?</h3>
              <div className="faq-arrow-wrapper" onClick={() => setFaq4(!faq4)}>
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq4 ? (
              <div className="faq-content">
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq" style={{color:"black"}}>
                We are now accepting applications for our  donations to the student 
          who will get the donations from us for their college tuition fees. If you 
          or anyone who would like to get your college degree for FREE, please send 
          your application to bananacoincrypto@gmail.com
       
          <br/>
          <ol className="paragraph-large-copy" style={{textAlign:"left",color:"black"}}>
            {/* Graduating from college often means high student debt Almost 40% of
            adults under the age of 30 are currently carrying some amount of
            student loan debt, with the amount of debt for the median borrower in
            2016 coming in at around $17,000. That’s a lot of debt to handle when
            you’re just starting out after college graduation. */}
            <li>Introducing yourself</li>
            <li>Your story</li>
            <li>Your background </li>
            <li> What college are you attending</li>
            <li>What major are you in</li>
            <li>Why do you deserve the donation from us</li>
            <li>What are your plans after graduation</li>

          </ol>
          Please share this post to everyone you know so that they will have a chance to get their
           college degrees for FREE! We will post your video on our social 
          media platforms and whoever has the highest votes will win the donations from us.
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div>
          <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">what is the official token ID?</h3>
              <div className="faq-arrow-wrapper" onClick={() => setFaq5(!faq5)}>
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq5 ? (
              <div className="faq-content">
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq" style={{color:"black"}}>
                  The official $BANANA token contract can be found here:{" "}
                  <a
                    href="https://bscscan.com/address/0x5fe86506985d7e87d16a83806c4007c15e50d24e"
                    target="_blank"
                  >
                    0x5fe86506985d7e87d16a83806c4007c15e50d24e
                  </a>
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div>
          <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">what is the charity wallet id?</h3>
              <div className="faq-arrow-wrapper" onClick={() => setFaq6(!faq6)}>
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq6 ? (
              <div className="faq-content">
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq" style={{color:"black"}}>
                  The official $BANANA charity wallet can be found here:{" "}
                  <a
                    href="https://bscscan.com/address/0x98859498d4C4dE0A9513404BB6f895F8651E66f8"
                    target="_blank"
                  >
                    0x98859498d4C4dE0A9513404BB6f895F8651E66f8
                  </a>
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div>
          <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">
                Why does the coin not show up in TrustWallet?
              </h3>
              <div className="faq-arrow-wrapper" onClick={() => setFaq7(!faq7)}>
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq7 ? (
              <div className="faq-content">
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq" style={{color:"black"}}>
                  There's a simple solution to this. You've got to add it as a
                  "custom token".
                  <br />
                  <br />
                  <strong>There are 3 steps:</strong>
                  <br />
                  <br />
                  <strong>1)</strong> Tap on the search token button and type
                  something in. Once you get the screen "add custom token" go
                  ahead and click it.
                  <br />
                  <strong>
                    <br />
                    2){" "}
                  </strong>
                  Get the custom token ID from banana coin. In this case you can
                  just copy the ID: &lt; TOKEN ID &gt; and fill it in.
                  <br />
                  <br />
                  <strong>3) </strong>Fill the token details with the name
                  aswell. Also make sure to select the binance smart chain.
                  <br />
                  <br />
                  <strong>4)</strong> The token should now be visible in your
                  wallet. If you still have questions, you can ask around in the
                  telegram. Source:{" "}
                  <a
                    href="https://community.trustwallet.com/t/how-to-add-a-custom-token/213"
                    target="_blank"
                  >
                    https://community.trustwallet.com/t/how-to-add-a-custom-token/213
                  </a>
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div>
          <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">
                Why is my $BANANA ppy not giving me a $USD amount in Trustwallet
                or Metamask?
              </h3>
              <div className="faq-arrow-wrapper" onClick={() => setFaq8(!faq8)}>
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq8 ? (
              <div className="faq-content">
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq" style={{color:"black"}}>
                  These amounts are pulled from listing services like
                  CoinMarketCap and CoinGecko. Because Banana Coin is still so
                  new, these services still need to index our token.
                  Essentially, this means that until we are listed on these
                  services, you won't be able to see your exact token amount in
                  your wallet.
                  <br />
                  <br />
                  That being said, you can connect your wallet to a service like{" "}
                  <a href="https://charts.bogged.finance/?token=0x5fe86506985d7E87d16A83806C4007C15E50D24E" target="_blank">
                    BOGTools
                  </a>
                  , or{" "}
                  <a href="https://poocoin.app/tokens/0x5fe86506985d7e87d16a83806c4007c15e50d24e" target="_blank">
                    PooCoin
                  </a>{" "}
                  to see your wallet balance.
                  <a
                    href="https://community.trustwallet.com/t/how-to-add-a-custom-token/213"
                    target="_blank"
                  ></a>
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div>
          <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">
                why are people saying this is rug-proof?
              </h3>
              <div className="faq-arrow-wrapper" onClick={() => setFaq9(!faq9)}>
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq9 ? (
              <div className="faq-content">
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq" style={{color:"black"}}>
                  Because we have renounced the contract (essentially it is not
                  able to be changed), developers are not able to go back in and
                  tamper with the code.&nbsp;This means the playing field is
                  fair game, and the token can go as far as the community, and
                  the team would like to push it.
                  <br />
                  <br />
                  Furthermore, the aggressive tax being added to liquidity
                  creates a larger and larger cushion, and reduces the price
                  volatility as the coin gains traction, effectively making
                  trading the token "comfier and comfier".
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div>
          {/* <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">
                <strong>
                  What does it mean to renounce ownership and burn liquidity?
                </strong>
              </h3>
              <div
                className="faq-arrow-wrapper"
                onClick={() => setFaq10(!faq10)}
              >
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq10 ? (
              <div className="faq-content">
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq">
                  Happycoin is a community token. By renouncing ownership, there
                  are no actions developers can take in altering the token or
                  its supply (so no mint feature can be called).
                  <br />
                  <br />
                  Shortly after launch, the LP tokens that can be used to redeem
                  the initial investment were sent to a dead address, or burned,
                  essentially meaning they are locked in the abyss forever
                  (that's a good thing)!
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div> */}
          {/* <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">
                <strong>
                  do you have proof of the LP burn or the contract being
                  renounced?
                </strong>
              </h3>
              <div
                className="faq-arrow-wrapper"
                onClick={() => setFaq11(!faq11)}
              >
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq11 ? (
              <div className="faq-content">
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq">
                  Yes we do!&nbsp;See below.
                  <br />
                  <br />
                  🔥&nbsp;&nbsp;&nbsp;
                  <a
                    href="https://bscscan.com/address/0xc249d26935881a838dbd044e5588e11c7859f808"
                    target="_blank"
                  >
                    LP&nbsp;Burned
                  </a>
                  <br />
                  📑&nbsp;&nbsp;&nbsp;
                  <a
                    href="https://bscscan.com/address/0x8fC4c9D8670a8552ba08cfA81e5A703174b8C7C5"
                    target="_blank"
                  >
                    Contract Renounced
                  </a>
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div> */}
          <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">
                <strong>Where can I find the dev team?</strong>
              </h3>
              <div
                className="faq-arrow-wrapper"
                onClick={() => setFaq12(!faq12)}
              >
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq12 ? (
              <div className="faq-content">
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq" style={{color:"black"}}>
                  {/* You can read the bio of the founder of HappyCoin (Sawyer
                  Wildgen){" "}
                  <a href="#Meet-The-Founder" className="">
                    here
                  </a>
                  .&nbsp;You can also see the founder's identity reveal video{" "}
                  <a
                    href="https://www.twitch.tv/videos/998345263"
                    target="_blank"
                  >
                    here
                  </a> */}
                  You can read the bio of the founder of $BANANA Coin (Chung
                  Ong) here. You can also see the founder's identity reveal
                  video here. .<br />
                  <br />
                  Doxxing (revealing your identity) is not the norm this soon
                  into the release of a project. Due to the volatility of the
                  crypto space, traders can get very emotional about price
                  movement, and doxxing exposes team members to additional risk.
                  For this reason, other team members are still waiting for
                  liquidity to build before making their identities public.
                  <br />
                  <br />
                  Rest assured that this entire team is in it for the long haul,
                  and there will be more and more team members that will be
                  comfortable doxxing as the project progresses.
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div>
          <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">is there an audit of the code?</h3>
              <div
                className="faq-arrow-wrapper"
                onClick={() => setFaq13(!faq13)}
              >
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq13 ? (
              <div className="faq-content">
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq" style={{color:"black"}}>
                  Yes there is, our first audit from Techrate can be found{" "}
                  <a
                  href={auditReportImg}
                    // href="https://assets-global.website-files.com/607e36bf55a23d54af1ad831/6082f6c4c6358f097570dead_HappyCoin_BEP-20_Audit_6819627.pdf"
                    target="_blank"
                  >
                    here 
                  </a> & Dessertswap <a href="https://dessertswap.finance/audits/Banana%20Coin%20BEP-20%20Transparency%20Audit%209128074.pdf" 
                  target="_blank">here </a>
                  .Many more audits will be coming in the following weeks.
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div>
          <div className="faq-wrapper">
            <div className="faq-top">
              <h3 className="title faq">
                do you have proof of actually donating?
              </h3>
              <div
                className="faq-arrow-wrapper"
                onClick={() => setFaq14(!faq14)}
              >
                <div className="faq-arrow-icon"></div>
              </div>
            </div>
            {faq14 ? (
              <div className="faq-content">
                <div className="space faq-top-content"></div>
                <p className="paragraph-large faq" style={{color:"black"}}>
                  Of course!&nbsp;A video of our lead developer doxxing as well
                  as donating to the first charity can be found{" "}
                  <a
                    // href="https://www.twitch.tv/videos/998345263"
                    target="_blank"
                  >
                    here
                  </a>
                  .
                </p>
                <div className="space faq-button-content"></div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default FAQ;
