import React, { useEffect, useCallback, useState } from "react";
import ModalBox from "../modal/index";
import Modal from "@material-ui/core/Modal";
import { useStore } from "../../../context/GlobalState";
import ConnectModal from "../modal/connectModal";
import Web3 from "web3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import Logo from "../../../images/bg-remove.png";
import OpeningTime from '../Timer/OpeningTime';
import ClosingTime from '../Timer/ClosingTime';
import Paper from '../paper/index';
import { loadBlockchain, loadWalletConnect,handleIsOpen,handleFinalize ,monkeyWithdraw} from "../../../store/asyncActions";

function Hero() {
  const [{ web3, accounts, network, monkeyContract }, dispatch] = useStore();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(false);
  const [openConnect, setOpenConnect] = React.useState(false);

  const [isOpen,setIsOpen] = useState(false);
  const [isFinalized,setIsFinalized] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //connect modal
  const handleConnectOpen = () => {
    setOpenConnect(true);
  };

  const handleConnectClose = () => {
    setOpenConnect(false);
  };
  useEffect(() => {
    if (web3 != null) {
      handleConnectClose();
    }
  }, [web3]);

  useEffect(() => {
    if (Web3.givenProvider != null && Web3.givenProvider.chainId != 0x38) {
      const notify = () =>
        toast.error("Please Connect With Binance Chain!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      notify();
    }
  }, [network]);
  console.log("network", network);

  useEffect(async()=>{
    if(monkeyContract !==null) {
      let op = await handleIsOpen(monkeyContract)
      let fl = await handleFinalize(monkeyContract)
      setIsFinalized(fl);
      setIsOpen(op)
    }

  },[monkeyContract,web3])
  const handleMonkeyWithDraw = async() => {
    try{
      await monkeyWithdraw(monkeyContract,accounts)
    }
    catch(error) {
      console.log("error",error)
    }
  }
  return (
    <div id="no" className="section hero hero-section-main">
      <div className="hero--container">
        <div className="img-container">
          <img src={Logo} alt="" className="image hero rotate" />
        </div>
        <div style={{display:"flex",justifyContent:"center"}}>
        <div>

            <div>
            <h3 className="opening-time-heading">
            Presale Starts In :
            </h3>
            <OpeningTime/>
            </div>
            <div style={{justifyContent:"center"}}>
            <h3 className="opening-time-heading">
            Presale Ends In :
            </h3>
            <ClosingTime/>
            </div>
            </div>

            </div>
        <div className="hero-coontent-container">
      
          <div className="title hero">
            <h2>
              Making Donations to the Hospitality Industry,
              <span className="text-span">one Hospitality Monkey Coin </span>
              At a time!
            </h2>

          </div>
          <br />
          <p className="paragraph-large hero hero-para">
            Hospitality Monkey Coin, a revolutionary charity-oriented token. Our
            mission is helping hospitality industry to get donations so that
            they can survive the negative impact of the deadly pandemic.Meet
            <strong className="bold-text"> $HMC Coin</strong>.
          </p>
        </div>
        <div className="hero-btn-container">
        
             {(web3 == null || (web3 == null && isOpen == true))  ?
              <a
              onClick={handleConnectOpen}
              style={{ textDecoration: "none", cursor: "pointer" }}
              className="main w-button"
            >
              Unlock Wallet
            </a>
            :
        ""
             }

              {web3 !==null && isOpen == true && isFinalized == false ?
               <a
               onClick={handleOpen}
               style={{ textDecoration: "none", cursor: "pointer" }}
               className="main w-button"
             >
               buy Monkey coin
             </a>
            :
       ""
             }

                 {web3 !==null && isOpen == true && isFinalized == true ?
               <a
               onClick={handleMonkeyWithDraw}
               style={{ textDecoration: "none", cursor: "pointer" }}
               className="main w-button"
             >
               WithDraw Token
             </a>
            :
            ""
             }
          <div className="space"></div>
          <a
            href="https://t.me/bananacoin88"
            target="_blank"
            className="button-secondary-copy w-button"
          >
            join the telegram
          </a>
        </div>
        <div className="paper-container-hero"> 
        <Paper/>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBox />
      </Modal>
      <Modal
        open={openConnect}
        onClose={handleConnectClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ConnectModal />
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default Hero;
