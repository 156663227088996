import React from "react";
import coFounder from '../../../images/co-founder.jpeg'
function CoF() {
  return (
    <section id="Meet-The-Co-Founder" className="section my-story">
      <div className="container-default w-container">
        <div className="my-story-wrapper">
          <div
            className="split-content my-story-left"
          >
            <img
              src={coFounder}
              // srcSet="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add6984d2c3d9a4_output-onlinepngtools-p-500.png 500w, https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add6984d2c3d9a4_output-onlinepngtools.png 800w"
              sizes="(max-width: 479px) 92vw, (max-width: 767px) 91vw, (max-width: 991px) 552px, (max-width: 1439px) 42vw, 553.078125px"
              alt=""
              className="image my-story"
              // width="600px"
              // height="600px"

            />
          </div>
          <div className="split-content my-story-right">
            <div
              className="accent"
            ></div>
            <h2
              data-w-id="9ea96bae-6205-c76e-fdfc-a105dc2e8578"
            >
              Meet the Cofounder
            </h2>
            <p className="paragraph-large">
            Hi, my name is YoYo, Before the pandemic, 
            I used to travel around China and sometimes to other countries to give lectures to my students. When
             pandemic hits, all my travel plans were changed and that made me very inconvenient and very dangerous 
             to travel. We know how the pandemic changed the hospitality industry especially airlines and the staffs 
             like pilots and flight attendants. A lot of people in the hospitality industry were affected by the 
             pandemic and we are here to help them to get through this difficult time. 
              {/* <a
                href="https://www.linkedin.com/in/sawyerwildgen/"
                target="_blank"
              >
               a struggling franchise in Orlando and I was the youngest franchisee in the system at that time
              </a>
              , and managed over 200 employees in my ventures collectively.
              After exiting my most recent company on January 1st, 2021
              I&nbsp;began to draft up the initial concepts for HappyCoin. */}
              <br />
              <br />
            
            </p>
            <div className="divider my-story"></div>
            {/* <h3
            >
              mental illness, and it's role in my life
            </h3>
            <p
              className="paragraph-large"
            >
              Mental illness has had a major impact on my life. With family
              members suffering from schizophrenia and bipolar disorder, I've
              witnessed first hand the stigma that comes along with these
              illnesses, and many times, the lack of effective outlets for
              relief, and treatment.&nbsp;This is what we're going to tackle on
              a global scale with HappyCoin.
            </p> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default CoF;