import React from 'react';
import video1 from '../../../videos/monkeyCoin1.mp4'
import video2 from '../../../videos/monkeyCoin2.mp4'
import video3 from '../../../videos/monkeyCoin3.mp4'
// import '../../App.css';
import './videoSection.css'


const VideoSection = () => {
    return (
        <div className="container platform box">
        <div className="title-box">
            {/* <h1 className="title" title="Reviews">
            WELCOME CTCWTF FRIENDS                </h1> */}
        </div>

        <div className="button-box">
            <div className="video1">
                <video controls  className="videos1" >
                    <source src={video1} type="video/mp4"   />
                </video>
                <h4 className="vs-heading">Arnel Pineda

Lead singer of Journey

Philanthropist </h4>

            </div>

            <div className="video1">
                <video controls  className="videos1"  >
                    <source src={video2} type="video/mp4"  />
                </video>
                <h4 className="vs-heading">Jerry Springer

Broadcaster, journalist, actor, producer, and former lawyer and politician.

Host of The Jerry Springer Show

Host of Judge Jerry</h4>
            </div>


            <div className="video1">
                <video controls  className="videos1"  >
                    <source src={video3} type="video/mp4"  />
                </video>
                <h4 className="vs-heading">Kurt Angle Olympic Gold Medalists 🏅 6X WWE Champion </h4>
            </div>

        </div>


    </div>
    )
}

export default VideoSection
