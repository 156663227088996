import "../../App.css";
import { EthAccountInfo } from "./ETHAccountInfo";
import Header from "./header";
import Hero from "./hero";
import WhyHappy from "./why-happy";
import FAQ from "./faq";
import Cta from "./cta";
import RoadMap from "./road-map";
import Footer from "./footer";
import ExtraSecTwo from "./extra/extraTwo";
import Founder from "./founder";
import Tokenomics from "./tokenomics";
import {Link} from 'react-router-dom'
import CoF from "./co-founder/";
import logo from "../../images/bananalogo.png";
import VideoSection from './videoSection/index'
import ContactUs from '../banana/contactUs/index'

function MonkeyCoin() {
  return (
    <div className="App monkey">
      <Header />
      <Hero />
      <WhyHappy />
      <Founder />
      <CoF/>
      <div>
      <VideoSection/>
      </div>
      <Tokenomics/>
      <RoadMap />
      <ExtraSecTwo />
      <ContactUs/>

      {/* <FAQ /> */}
      {/* <Cta /> */}
      <Footer />
      <button className="floating-btn">
      <Link to="/" style={{textDecoration:"none"}}>
        <img src={logo} alt="btn" />
       {/* <p style={{fontSize:"14px"}}> Banana Coin</p> */}
        </Link>
      </button>
    </div>
  );
}

export default MonkeyCoin;
