import React from "react";
import Twitter from "../twitt.jpg";

function ExtraSecTwo() {
  return (
    <div
      id="why-happy2"
      style={{minHeight: "800px", maxHeight: "1038px"}}
      className="section extra products-copy-copy-copy"
    >
      <div className="container-medium-655px products w-container">
        <div className="accent featured-products"></div>
        <h3 className="heading-2">
          Hospitality Monkey Coin is a charity-oriented token that is setting
          out to change the way we think about Hospitality Industry with huge
          partnerships to make the world to become CASHLESS!
        </h3>
        <img src={Twitter} style={{width: "500px", height: "600px"}}/>

        {/* <a
        href="https://firebasestorage.googleapis.com/v0/b/cdn-clients-other-use.appspot.com/o/images%2Fdoc.docx?alt=media&token=9d565636-b1ce-447b-ab53-7814ca94a87a"
          target="_blank"
          className="button-secondary-copy-copy-copy-copy-copy-copy w-button"
        >
          read the whitepaper
        </a>
        <a
          target="_blank"
          className="button-secondary-copy-copy-copy-copy-copy-copy-copy w-button"
        >
          see the Dessertswap audit
        </a> */}
      </div>
      <div id="why-happy" className="lottie-animation-copy-copy"></div>
    </div>
  );
}

export default ExtraSecTwo;
