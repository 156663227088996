
import React,{useState} from 'react'
import emailjs from "emailjs-com";
import './contact.css'
import useForm from "./useForm";
import validate from "./validationRules";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';


const Contact = () => {
  const { values, handleChange, handleSubmit } = useForm(validate);

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm('service_p7oqkba', 'template_ybsfuvj', e.target, 'user_G4wTrO3yfKjFtAYQKAr8G')
    .then((result) => {
      const notify = () => toast.info('Successful', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
    });;
    notify();
    e.target.reset()
        console.log(result.text);

    }, (error) => {
      const notify = () => toast.error('failed', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
    });;
    notify();
    e.target.reset()
        console.log(error.text);
    });
}

  return (
 <>
 <div class="background" id="contactUs">
  <div class="container-contact">
        <ToastContainer />

    <div class="screen">
      <div class="screen-header">
        <div class="screen-header-left">
          <div class="screen-header-button close"></div>
          <div class="screen-header-button maximize"></div>
          <div class="screen-header-button minimize"></div>
        </div>
        <div class="screen-header-right">
          <div class="screen-header-ellipsis"></div>
          <div class="screen-header-ellipsis"></div>
          <div class="screen-header-ellipsis"></div>
        </div>
      </div>
      <div class="screen-body">
        <div class="screen-body-item left">
          <div class="app-title">
            <span>Contact Us</span>
            {/* <span></span> */}
          </div>
        </div>
        <form onSubmit={sendEmail}  class="screen-body-item"> 

        {/* <div class="screen-body-item"> */}
          <div class="app-form">
            <div class="app-form-group">
              <input class="app-form-control" placeholder="EMAIL"
                 value={values.email || ""}
                 name="email"

                 onChange={handleChange}/>
            </div>
            <div class="app-form-group">
              <input class="app-form-control" placeholder="YOUR NAME"
               name="name"
              value={values.name || ""}
              onChange={handleChange}
            />
            </div>
            <div class="app-form-group message">
              <textarea  class="app-form-control" placeholder="MESSAGE"
                value={values.message || ""}
                name="message"
                // id="Message"
                onChange={handleChange}/>
            </div>
            <div class="app-form-group buttons">
              <button class="app-form-button">SEND</button>
            </div>
          </div>
        {/* </div> */}
        </form>

      </div>
    </div>
   
  </div>
</div>

 </>
  );
};

export default Contact;
