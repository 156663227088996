import React, { useEffect, useCallback, useState } from "react";
import MenuIcon from "@material-ui/icons/Menu";
import Modal from "@material-ui/core/Modal";
import { useStore } from "../../../context/GlobalState";
import { loadBlockchain, loadWalletConnect,handleIsOpen,handleFinalize ,monkeyWithdraw} from "../../../store/asyncActions";
import ModalBox from "../modal/index";
import ConnectModal from "../modal/connectModal";
import Logo from "../../../images/bg-remove.png";

function Header() {
  const [{ web3, accounts, contract,monkeyContract }, dispatch] = useStore();
  const [userAccount, setUserAccount] = useState("");

  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = useState(false);
  const [openConnect, setOpenConnect] = useState(false);
  const [isOpen,setIsOpen] = useState(false);
  const [isFinalized,setIsFinalized] = useState(false);


  const handleMetamask = useCallback(async () => {
    loadBlockchain(dispatch);
  }, []);
  const handleWalletConnect = useCallback(async () => {
    loadWalletConnect(dispatch);
  }, []);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleConnectOpen = () => {
    setOpenConnect(true);
  };

  const handleConnectClose = () => {
    setOpenConnect(false);
  };
  useEffect(() => {
    if (web3 != null) {
      handleConnectClose();
    }
  }, [web3]);
  useEffect(async()=>{
    if(monkeyContract !==null) {
      let op = await handleIsOpen(monkeyContract)
      let fl = await handleFinalize(monkeyContract)
      console.log("monkeyContractmonkeyContract",monkeyContract)


      setIsFinalized(fl);
      setIsOpen(op)
    }

  },[monkeyContract,web3])
  useEffect(async () => {
    if (web3 != null) {
      let accountCheck = await accounts[0];
      setUserAccount(accountCheck);
    }
  }, [accounts]);

  const handleMonkeyWithDraw = async() => {
    try{
      await monkeyWithdraw(monkeyContract,accounts)
    }
    catch(error) {
      console.log("error",error)
    }
  }

  console.log("opop",isOpen);
  console.log("flfl",isFinalized)
  return (
    <div className="header w-nav">
      <nav className="navbar navbar-expand-lg">
        <div className="container">
          <a className="navbar-brand brand w-nav-brand w--current" href="#">
            <img
              // src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69b469c3d95f_happy%20logo.png"
              src={Logo}
              width="100"
              alt="logo"
              className="header-logo"
            />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <MenuIcon />
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper-2"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="#why-happy2"
                >
                  why $HMC
                </a>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper-2"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="#tokenomics"
                >
                  tokenomics
                </a>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper-2"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="#roadmap"
                >
                  roadmap
                </a>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper-2"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="#Meet-The-Founder"
                >
                  meet the founder
                </a>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper-2"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="#Meet-The-Co-Founder"
                >
                  cofounder
                </a>
              </li>

               <li
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper-2"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="#contactUs"
                >
                  Contact Us
                </a>
              </li>
              <li
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                className="nav-item nav-item-wrapper"
              >
                <a
                  className="nav-link nav-link-2"
                  aria-current="page"
                  href="http://nft.banana-coin.org/"
                  target="_blank"
                >
                 NFT Marketplace
                </a>
              </li>
              {/* {web3 == null && userAccount ==  undefined ?
              "" :
              <li className="navLink whitepaper">
                <a className="address-" href="#">
                  {`${(userAccount).slice(0,6)}....${(userAccount).slice(36,42)}`}
                </a>
              </li>
            } */}
            </ul>
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            </ul>


              {(web3 == null || (web3 == null && isOpen == true))  ?
              <a
              onClick={handleConnectOpen}
              style={{ textDecoration: "none", cursor: "pointer" }}
              className="buy-happy-button"
            >
              Unlock Wallet
            </a>
            :
        ""
             }

              {web3 && isOpen == true && isFinalized == false ?
               <a
               onClick={handleOpen}
               style={{textTransform:"capitalize", textDecoration: "none", cursor: "pointer" }}
               className="buy-happy-button"
             >
               buy <span style={{textTransform:"uppercase"}}>$hmc</span>
             </a>
            :
       ""
             }

                 {web3 && isOpen == true && isFinalized == true ?
               <a
               onClick={handleMonkeyWithDraw}
               style={{textTransform:"capitalize", textDecoration: "none", cursor: "pointer" }}
               className="buy-happy-button"
             >
               Claim <span style={{textTransform:"uppercase"}}>$hmc</span>
             </a>
            :
            ""
             }
          </div>
        </div>
      </nav>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ModalBox />
      </Modal>

      <Modal
        open={openConnect}
        onClose={handleConnectClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <ConnectModal />
      </Modal>
    </div>
  );
}

export default Header;
