import { setupWeb3, setupContract, isFinalize, isOpen, setNetwork, setMonkeyContract, addEthereumAccounts, addTransaction, web3LoadingError } from "./actions";
import Web3 from "web3";
import { CONTRACT_ABI, CONTRACT_ADDRESS } from '../contract/ABI';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { MONKEY_ADDRESS, MONKEY_ABI } from '../contract/monkeyCoin';
export const loadBlockchain = async (dispatch) => {
    try {
        console.log("Web3 = ", Web3);
        console.log("Web3.givenProvider = ", Web3.givenProvider.chainId);
        dispatch(setNetwork(Web3.givenProvider.chainId));
        if(Web3.givenProvider && Web3.givenProvider.chainId ==  0x38){
        // if (Web3.givenProvider) {
            const web3 = new Web3(Web3.givenProvider);
            await Web3.givenProvider.enable();
            dispatch(setupWeb3(web3));
            const contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
            const monkeyContract = new web3.eth.Contract(MONKEY_ABI, MONKEY_ADDRESS);

            dispatch(setupContract(contract));
            const accounts = await web3.eth.getAccounts();
            dispatch(addEthereumAccounts(accounts));
            dispatch(setMonkeyContract(monkeyContract))

            //   console.log("monkeyContract",monkeyContract.methods)
        }
        else {
            dispatch(web3LoadingError("Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"))
        }
    }
    catch (error) {
        console.log("Error in loading Web3 = ", error);
        if (error.code === 4001) {

            dispatch(web3LoadingError(error.message));
        }
    }
}

export const loadWalletConnect = async (dispatch) => {
    try {
        const provider = new WalletConnectProvider({

            rpc: {
                56: "https://bsc-dataseed.binance.org/",
            },
            rpcUrl: "https://bsc-dataseed.binance.org/",
            chainId: 56
        });
        if (provider) {
            await provider.enable();

            const web3 = new Web3(provider);
            console.log(web3)

            // await Web3.givenProvider.enable();
            dispatch(setupWeb3(web3));
            const contract = new web3.eth.Contract(CONTRACT_ABI, CONTRACT_ADDRESS);
            console.log("this is for contract", contract)
            const monkeyContract = new web3.eth.Contract(MONKEY_ABI, MONKEY_ADDRESS);

            dispatch(setupContract(contract));
            const accounts = await web3.eth.getAccounts();
            console.log("this is for accounts", accounts)
            dispatch(addEthereumAccounts(accounts));
            // console.log("contract = ", contract);
            // console.log("contract.methods = ", contract.methods);
            dispatch(setMonkeyContract(monkeyContract))

        }
        else {
            dispatch(web3LoadingError("Please install an Ethereum-compatible browser or extension like MetaMask to use this dApp!"))
        }
    }
    catch (error) {
        console.log("Error in loading Web3 = ", error);
        if (error.code === 4001) {

            dispatch(web3LoadingError(error.message));
        }
    }
}
export const buyTokensAsync = async (beneficiary, accounts, contract, etherValue, dispatch) => {
    console.log("before transaction");
    const price = etherValue.toString(); //change it
    const receipt = await contract.methods
        .buyTokens(beneficiary)
        .send({ from: accounts[0], value: price });
    console.log("after  transaction ", receipt);
}

// https://www.bakeryswap.org/?utm_source=DappRadar&utm_medium=deeplink&utm_campaign=visit-website#/home

export const remainingTokensAsync = async (contract) => {
    const receipt = await contract.methods
        .remainingTokens().call();
    console.log("after remainingTokensAsync  transaction ", receipt);
    return receipt
}

export const weiRaisedAsync = async (contract) => {
    const receipt = await contract.methods
        .weiRaised().call();
    console.log("after weiRaisedAsync  transaction ", receipt / 10e7);
    return receipt

}



//monekyCoin


export const buyMonkeyTokensAsync = async (beneficiary, accounts, contract, etherValue, dispatch) => {
    console.log("before transaction");
    const price = etherValue.toString(); //change it
    const receipt = await contract.methods
        .buyTokens(beneficiary)
        .send({ from: accounts[0], value: price });
    console.log("after  transaction ", receipt);
}

// https://www.bakeryswap.org/?utm_source=DappRadar&utm_medium=deeplink&utm_campaign=visit-website#/home

export const remainingMonkeyTokensAsync = async (contract) => {
    const receipt = await contract.methods
        .remainingTokens().call();
    console.log("after remainingTokensAsync  transaction ", receipt);
    return receipt / 10e17
}

export const monkeyWeiRaisedAsync = async (contract) => {
    const receipt = await contract.methods
        .weiRaised().call();
    console.log("after weiRaisedAsync  transaction ", receipt / 10e7);
    return receipt / 10e17

}

export const claimRefundAsync = async (contract, accounts) => {
    const receipt = await contract.methods
        .claimRefund().send({ from: accounts[0] });;
    console.log("after weiRaisedAsync  transaction ", receipt / 10e7);
    return receipt
}

export const handleIsOpen = async (contract) => {
    const receipt = await contract.methods.isOpen().call();
    console.log("handleIsOpen", receipt)
    return receipt

}

export const handleFinalize = async (contract) => {
    const receipt = await contract.methods.finalized().call();
    console.log("isOpen", receipt)
    return receipt

}


export const openingTime = async (contract) => {
    const receipt = await contract.methods
        .openingTime().call();
    let date = new Date(receipt * 1000);
    console.log("openingTime", date);
    console.log("openingTime", receipt);

    return receipt
}

export const closingTime = async (contract) => {
    const receipt = await contract.methods
        .closingTime().call();
    let date = new Date(receipt * 1000);

    console.log("closingTime", date);
    console.log("closingTime", receipt);

    return receipt
}

export const monkeyRate = async (contract) => {
    const receipt = await contract.methods
        .rate().call();
    console.log("receipt", receipt);
    return receipt
}

export const monkeyWithdraw = async (contract,accounts) => {
    const receipt = await contract.methods
        .withdrawTokens(accounts[0]).send({ from: accounts[0]});
    console.log("receipt", receipt);
    return receipt
}

// export const maxMonkeyCoins = async(contract) => {
//     const receipt = await contract.methods
//     .getMaxContribution().call();
// console.log("receipt", receipt);
// return receipt/10e17
// }

// export const minMonkeyCoins = async(contract) => {
//     const receipt = await contract.methods
//     .getMinimumContribution().call();
// console.log("receipt", receipt);
// return receipt/10e17
// }