import React from "react";

function ExtraSec() {
  return (
    <div
      id="why-happy2"
      style={{minHeight: "1000px", maxHeight: "1500px"}}
      className="section extra products-copy-copy-copy"
    >
      <div className="container-medium-655px products w-container">
        <div className="accent featured-products"></div>
        {/* <h1 className="heading-2">the world needs $BANANA Coin</h1> */}
        <p className="paragraph-large-copy">
          While we wait for the outcomes of these discussions, today’s students
          are facing difficult decisions about their futures, including whether
          or not college is an affordable expense. Fortunately, there are a
          number of highly-rated organizations committed to helping students
          from all walks of life get a degree that will help them build brighter
          futures for themselves, their families, their communities, our
          country, and our world. 
          {/* <strong className="bold-text-2">Banana Coin</strong> is a token that
          sets out to change this. */}
        </p>
        <br />

        <p className="paragraph-large-copy">
          It’s easy to think of someone else’s education expenses as “their”
          issue, but access to education should be an issue we all stand behind
          because it has a personal, social, and economic impact on all of us.
        </p>

        <p className="paragraph-large-copy">
          What’s the return on your investment in $BANANA Coin? The research is
          clear: college graduates earn more, live better lives and contribute
          more to local and global communities. When you invest in $BANANA Coin,
          you do more than touch individual lives because every contribution
          ripples outward in what’s called “social return”—the positive impact
          of education on the entire world.{" "}
        </p>

        <p className="paragraph-large-copy">
          Think about it. Your one contribution has the potential to make a
          difference for millions—as well as for many deserving, talented young
          individuals.
        </p>

        {/* <a
        href="https://firebasestorage.googleapis.com/v0/b/cdn-clients-other-use.appspot.com/o/images%2Fdoc.docx?alt=media&token=9d565636-b1ce-447b-ab53-7814ca94a87a"
          target="_blank"
          className="button-secondary-copy-copy-copy-copy-copy-copy w-button"
        >
          read the whitepaper
        </a>
        <a
          target="_blank"
          className="button-secondary-copy-copy-copy-copy-copy-copy-copy w-button"
        >
          see the Dessertswap audit
        </a> */}
      </div>
      <div id="why-happy" className="lottie-animation-copy-copy"></div>
    </div>
  );
}

export default ExtraSec;
