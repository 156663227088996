import React from "react";
import Graduate from '../../../images/graduate.jpeg'
function index() {
  return (
    <div id="where-we-donate" className="section about-hero">
      <div className="container-default w-container">
        <div className="about-hero-wrapper">
          <div
            className="card about-hero"
          >
            <div className="accent about-hero"></div>
            <h1 className="title about-hero" style={{color:"black"}}>how are charities decided?</h1>
            <p className="paragraph-large-copy"  style={{color:"black"}}>
          We are now accepting applications for our  donations to the student 
          who will get the donations from us for their college tuition fees. If you 
          or anyone who would like to get your college degree for FREE, please send 
          your application to bananacoincrypto@gmail.com

          </p>
  
          <p className="paragraph-large-copy" style={{textAlign:"left",color:"black"}}>
          We need you to record a video:
          </p>
        <br/>
          <ol className="paragraph-large-copy" style={{textAlign:"left",color:"black"}}>
            {/* Graduating from college often means high student debt Almost 40% of
            adults under the age of 30 are currently carrying some amount of
            student loan debt, with the amount of debt for the median borrower in
            2016 coming in at around $17,000. That’s a lot of debt to handle when
            you’re just starting out after college graduation. */}
            <li>Introducing yourself</li>
            <li>Your story</li>
            <li>Your background </li>
            <li> What college are you attending</li>
            <li>What major are you in</li>
            <li>Why do you deserve the donation from us</li>
            <li>What are your plans after graduation</li>

          </ol>
          {/* <a
          href="https://firebasestorage.googleapis.com/v0/b/cdn-clients-other-use.appspot.com/o/images%2Fdoc.docx?alt=media&token=9d565636-b1ce-447b-ab53-7814ca94a87a"
            target="_blank"
            className="button-secondary-copy-copy-copy-copy-copy-copy w-button"
          >
            read the whitepaper
          </a>
          <a
            target="_blank"
            className="button-secondary-copy-copy-copy-copy-copy-copy-copy w-button"
          >
            see the Dessertswap audit
          </a> */}
                  <br/>

          <p className="paragraph-large-copy" style={{color:"black"}}>
          Please share this post to everyone you know so that they will 
          have a chance to get their college degrees for FREE! We will post
           your video on our social media platforms and whoever has the highest
            votes will win the donations from us.
          </p>
            <div className="_2-buttons">
              <div className="space"></div>
            </div>
          </div>
          <div
            className="image-wrapper about-hero"
          >
            <img
              // src="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69228cc3d9a5_pexels-polina-zimmerman-3958461.jpg"
              // srcSet="https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69228cc3d9a5_pexels-polina-zimmerman-3958461-p-1080.jpeg 1080w, https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69228cc3d9a5_pexels-polina-zimmerman-3958461-p-1600.jpeg 1600w, https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69228cc3d9a5_pexels-polina-zimmerman-3958461-p-2000.jpeg 2000w, https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69228cc3d9a5_pexels-polina-zimmerman-3958461-p-2600.jpeg 2600w, https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69228cc3d9a5_pexels-polina-zimmerman-3958461-p-3200.jpeg 3200w, https://assets-global.website-files.com/609228790add69015dc3d8a0/609228790add69228cc3d9a5_pexels-polina-zimmerman-3958461.jpg 5760w"
              src={Graduate}
              sizes="(max-width: 479px) 92vw, (max-width: 991px) 94vw, (max-width: 1439px) 65vw, 854.75px"
              alt=""
              className="image about-hero"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default index;
