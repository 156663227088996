import React from "react";

function RoadMap() {
  return (
    <div id="roadmap" className="section-copy experience">
      <div className="container-large-1131px w-container">
        <div className="experience-wrapper">
          <div className="split-content experience-left">
            <div
              data-w-id="d23b6533-20aa-100c-6817-34ece76dab37"
              className="experience-left-content"
            >
              <div className="accent"></div>
              <h2>roadmap</h2>
              <p className="paragraph-large">
                We can’t simply believe in equality in education. Equality in
                Education Isn’t Enough. We have to create it. Check out our
                roadmap and see what we have planned to keep this community and
                charity going. Community events, giveaways, an NFT marketplace,
                and merchandise are all on the way!
              </p>
            </div>
          </div>
          <div className="split-content">
            <div className="w-layout-grid timeline-grid">
              <div
                id="w-node-d23b6533-20aa-100c-6817-34ece76dab3f-5fc3d8a1"
                className="timeline-wrapper"
              >
                <div className="timeline-dot"></div>
                <div className="timeline-dot"></div>
                <div className="timeline-dot"></div>
                <div className="timeline-dot"></div>
                <div className="timeline-dot"></div>

                <div className="timeline-line-wrapper">
                  <div className="time-line"></div>
                </div>
              </div>
              {/* <div> */}
              <div className="card timeline">
                <h4 className="title card-timeline-title">2022 Q1</h4>
                <div className="card-timeline-text">
                  💎&nbsp;&nbsp;&nbsp;NFT Marketplace <br />
                  🌐 &nbsp;&nbsp;&nbsp;&nbsp; Website Improvements
                  <br />
                  🌟 &nbsp; Launching Crowdfunding platform&nbsp;&nbsp;
                  <br />
                </div>
              </div>
              <div
                data-w-id="d23b6533-20aa-100c-6817-34ece76dab4d"
                className="card timeline"
              >
                <h4 className="title card-timeline-title">2022 Q2</h4>
                <div className="card-timeline-text">
                  🌟&nbsp;&nbsp;&nbsp;
                  <a target="_blank">Influencer Marketing</a>
                  &nbsp;
                  <a target="_blank"></a> <br />
                  👋&nbsp;&nbsp;&nbsp; Community Livestreams
                  <br />
                </div>
              </div>
              <div className="card timeline">
                <h4 className="title card-timeline-title">2022 Q3</h4>
                <div className="card-timeline-text">
                  😊&nbsp;&nbsp;&nbsp; $HMC Launch
                  <br />
                  🥰&nbsp;&nbsp;&nbsp; More Donations
                  <br />
                </div>
              </div>
              <div className="card timeline">
                <h4 className="title card-timeline-title">2022 Q4</h4>
                <div className="card-timeline-text">
                  🤝&nbsp;&nbsp;&nbsp; Major Influencer Partnerships
                </div>
                <div className="card-timeline-text">
                  🎥&nbsp;&nbsp;&nbsp; $BANANA Vlogs
                </div>
                <div className="card-timeline-text">
                  🤫&nbsp;&nbsp;&nbsp;Other Major CEX Listings
                </div>
              </div>
              <div className="card timeline">
                <h4 className="title card-timeline-title">2023 Q1</h4>
                <div className="card-timeline-text">
                  🤫&nbsp;&nbsp;&nbsp; <em>More Coming Soon...</em>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoadMap;
