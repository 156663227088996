import React, { useState, useEffect } from "react";
import {
  remainingMonkeyTokensAsync,
  monkeyWeiRaisedAsync,
} from "../../../store/asyncActions";
import { useStore } from "../../../context/GlobalState";
import "./paper.css";

const Paper = () => {
  const [{ web3, accounts, monkeyContract }, dispatch] = useStore();

  const [remainingTokne, setRemainingTokens] = useState();
  const [weiRaised, setWeiRaised] = useState();
  console.log("monkeyContract", monkeyContract);
  useEffect(async () => {
    if (monkeyContract != null) {
      let rt = await remainingMonkeyTokensAsync(monkeyContract);
      let wr = await monkeyWeiRaisedAsync(monkeyContract);
      setRemainingTokens(rt);
      setWeiRaised(wr);
    }
  }, [web3, monkeyContract]);
  console.log("remainingTokne", remainingTokne);
  console.log("weiRaised", weiRaised);

  return (
    <>
    {
      web3 === null ?
      <h3 style={{color:"white"}}>Connect Wallet to view the values given below</h3>
      :
      ""
    }
      <div className="paper-container">
        <div className="paper-inner-container">
          <div className="paper-remainig-container">
            <h3 className="paper-heading-remaining">Remaining Tokens</h3>
            <h3 className="paper-remaining">
              {remainingTokne
                ? remainingTokne
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : ""}
            </h3>
          </div>
          <div className="paper-remainig-container">
            <h3 className="paper-heading-remaining">BNB Raised</h3>
            <h3 className="paper-remaining">
              {weiRaised || weiRaised >= 0
                ? weiRaised.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
                : ""}
            </h3>
          </div>
        </div>
      </div>
    </>
  );
};

export default Paper;
