import React from "react";
import WhitePaper from '../$HMC Coin White Paper PDF.pdf'
function WhyHappy() {
  return (
    <div
      id="why-happy2"
      style={{minHeight: "1000px", maxHeight: "1200px"}}
      className="section happy products-copy-copy-copy"
    >
      <div className="container-medium-655px products w-container">
        <div className="accent featured-products"></div>
        <h1 className="heading-2">The world needs $HMC Coin</h1>
        <p className="paragraph-large-copy">
          Many industries were affected by the coronavirus (COVID-19) outbreak.
          But none more so than the hospitality industry. Bars, restaurants,
          hotels, airlines and cruise ships are experiencing a dramatic decline
          in customer traffic. To put it bluntly, the situation downright sucks.
          <br />
          We are eager to change the way you pay for the meals at the
          restaurants and bars or paying for the hotel stays with our
          Hospitality Monkey Coin, while earning you money. We believe that the
          world should be CASHLESS and that is why we are here. Hospitality
          Monkey Coin is a standard token that you don’t have to pay taxes when
          you buy or sell. That would be perfect to make any transactions
          without losing any taxes. We will have our own app and wallets
          underdevelopment so that you can pay directly to anyone, anytime,
          anywhere.
          {/* <strong className="bold-text-2">Banana Coin</strong> is a token that
          sets out to change this. */}
        </p>
        <a
          // href="https://firebasestorage.googleapis.com/v0/b/cdn-clients-other-use.appspot.com/o/images%2Fdoc.docx?alt=media&token=9d565636-b1ce-447b-ab53-7814ca94a87a"
          href={WhitePaper}
          target="_blank"
          className="button-secondary-copy-copy-copy-copy-copy-copy w-button"
        >
          read the whitepaper
        </a>
        <a
        href="https://dessertswap.finance/audits/Hospitality%20Monkey%20Coin%20BEP-20%20Audit%209128066.pdf"
          target="_blank"
          className="button-secondary-copy-copy-copy-copy-copy-copy-copy w-button"
        >
          see the Dessertswap audit
        </a>
      </div>
      <div id="why-happy" className="lottie-animation-copy-copy"></div>
    </div>
  );
}

export default WhyHappy;
