import React, { useEffect, useCallback, useState } from "react";
import ModalBox from "../modal/index";
import Modal from "@material-ui/core/Modal";
import { useStore } from "../../../context/GlobalState";
import ConnectModal from "../modal/connectModal";
import Web3 from "web3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import Logo from "../../../images/banana-big.png";
function Hero() {
  const [{ web3, accounts, network, contract }, dispatch] = useStore();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [open, setOpen] = React.useState(false);
  const [openConnect, setOpenConnect] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //connect modal
  const handleConnectOpen = () => {
    setOpenConnect(true);
  };

  const handleConnectClose = () => {
    setOpenConnect(false);
  };
  useEffect(() => {
    if (web3 != null) {
      handleConnectClose();
    }
  }, [web3]);

  useEffect(() => {
    if (Web3.givenProvider != null && Web3.givenProvider.chainId != 0x38) {
      const notify = () =>
        toast.error("Please Connect With Binance Chain!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
        });
      notify();
    }
  }, [network]);
  console.log("network", network);
  return (
    <div id="no" className="section hero hero-section-main">
    <div className="hero--container">
      <div className="img-container">
        <img src={Logo} alt="" className="image hero rotate" />
      </div>
      <div className="hero-coontent-container">
      <div className="title hero">
              <h1>
                Giving free
                education,
                <span className="text-span">one $BANANA Coin </span>
                at a time
              </h1>
            </div>
        <br />
        <p className="paragraph-large hero hero-para">
        A charity-oriented token that is helping to pay for education
              across the world. Meet
              <strong className="bold-text" style={{color:"#A54C7F"}}> $BANANA Coin</strong>.
        </p>
      </div>
      <div className="hero-btn-container">
        {/* {web3 != null ? (
          <a 
          // onClick={handleOpen}
          style={{cursor:"not-allowed"}}
           className="main w-button">
            buy Banana Coin
            
          </a>
        ) : (
          <a className="main w-button" style={{cursor:"not-allowed"}}>
            Unlock Wallet
          </a>
        )} */}
        <div className="space"></div>
        <a
          href="https://t.me/bananacoin88"
          target="_blank"
          className="button-secondary-copy w-button"
        >
          join the telegram
        </a>
      </div>
    </div>
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ModalBox />
    </Modal>
    <Modal
      open={openConnect}
      onClose={handleConnectClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <ConnectModal />
    </Modal>
    <ToastContainer />
  </div>
  );
}

export default Hero;
