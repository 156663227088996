import React , {useState,useEffect} from 'react'
import "./App.css";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GlobalProvider } from "./context/GlobalState";
import Banana from "./components/banana/"
import MonkeyCoin from "./components/monkey";
import LandingModal from './landingModal/index';
import Modal from "@material-ui/core/Modal";

function App() {
  const [openConnect, setOpenConnect] = useState(false);
  const handleConnectClose = () => {
    setOpenConnect(false);
  };

  useEffect(()=>{
      setOpenConnect(true);
  
  },[])
  return (
    <>
      <GlobalProvider>
        <Router>
          <Switch>
            <Route path="/" exact component={Banana}/>
            <Route path="/monkey_coin" component={MonkeyCoin}/>
          </Switch>
          <Modal
        open={openConnect}
        onClose={handleConnectClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <LandingModal handleConnectClose={handleConnectClose}/>
      </Modal>
        </Router>
      </GlobalProvider>


    </>
  );
}

export default App;
