import React from "react";

function ExtraSecTwo() {
  return (
    <div
      id="why-happy2"
      style={{minHeight: "1000px", maxHeight: "1500px"}}
      className="section extra products-copy-copy-copy"
    >
      <div className="container-medium-655px products w-container">
        <div className="accent featured-products"></div>
        <h1 className="heading-2">Why Students Need Extra Help</h1>

        <p className="paragraph-large-copy">
          For many families, paying for a child’s college education entirely on
          their own is out of the question. Here are a few very big reasons
          students need all the extra help they can get:{" "}
        </p>

        <p className="paragraph-large-copy">
          According to Department of Education statistics, after adjusting for
          inflation, the average cost (tuition, fees, room and board) for a
          full-time undergraduate student at a four-year college in the U.S. in
          1985-86 was $12,052 ($5,504 in 1985-86’s dollars). In 2015-16, the
          cost was $26,120. That can be a lot to come up with, especially for
          students who may not have much financial support at home. The money
          students can raise from traditional sources often isn’t not enough For
          many students, a traditional student loan package doesn’t quite cover
          all their expenses. They may have their tuition covered but still
          struggle to pay for books or meals. Or they may not be eligible for
          enough financial aid to cover their dream school.
        </p>

        <p className="paragraph-large-copy">
          Graduating from college often means high student debt Almost 40% of
          adults under the age of 30 are currently carrying some amount of
          student loan debt, with the amount of debt for the median borrower in
          2016 coming in at around $17,000. That’s a lot of debt to handle when
          you’re just starting out after college graduation.
        </p>
        {/* <a
        href="https://firebasestorage.googleapis.com/v0/b/cdn-clients-other-use.appspot.com/o/images%2Fdoc.docx?alt=media&token=9d565636-b1ce-447b-ab53-7814ca94a87a"
          target="_blank"
          className="button-secondary-copy-copy-copy-copy-copy-copy w-button"
        >
          read the whitepaper
        </a>
        <a
          target="_blank"
          className="button-secondary-copy-copy-copy-copy-copy-copy-copy w-button"
        >
          see the Dessertswap audit
        </a> */}
      </div>
      <div id="why-happy" className="lottie-animation-copy-copy"></div>
    </div>
  );
}

export default ExtraSecTwo;
