import "../../App.css";
import { EthAccountInfo } from "./ETHAccountInfo";
import Header from "./header";
import Hero from "./hero";
import WhyHappy from "./why-happy";
import Tokenomics from "./tokenomics";
import FAQ from "./faq";
import Cta from "./cta";
import RoadMap from "./road-map";
import Footer from "./footer";
import Founder from "./founder";
import CoF from "./co-founder/";
import Donate from "./donate";
import ExtraSec from "./extra/";
import ExtraSecTwo from "./extra/extraTwo";
import ContactUs from './contactUs/index'
import {Link} from 'react-router-dom'
import logo from "../../images/bg-remove.png";
import VideoSection from './videoSection/index'
function Banana() {
  return (
    <div className="App monkey" style={{position: "relative"}}>
      <Header />
      <Hero />
      <WhyHappy />
      <VideoSection/>
      <Tokenomics />
      <ExtraSec />
      <RoadMap />
      <Founder />
      <CoF />
      <Donate />
      <ExtraSecTwo />
      <FAQ />
      <Cta />
      <ContactUs/>
      <Footer />

      <button className="floating-btn">
      <Link to="/monkey_coin" style={{textDecoration:"none"}}>
        <img src={logo} alt="btn" />
        {/* <p style={{fontSize:"14px"}}> Monkey Coin</p> */}

        </Link>

      </button>
    </div>
  );
}

export default Banana;
